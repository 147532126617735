<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="cols-12">
          <h2>A EMPRESA</h2>
          <br />
          <p>
            A Plataforma de Leilões
            <a href="https://www.ebonline.com.br">EBLONLINE.COM.BR</a>, Empresa
            Brasileira de Leilões é a mais nova, segura e moderna ferramenta
            integrada aos sistemas desenvolvidos pela
            <strong>WEBZI Pesquisa & Inovação Tecnológica Ltda.</strong>
          </p>
          <p>
            Atuamos a 11 anos dando suporte a parceiros integradores,
            leiloeiros, órgãos executivos e gestores de pátios. Oferecemos nossa
            experiência comprovada na execução e suporte a processos de
            Pré-leilão, Leilões Online, Leilões Presenciais, e Pós-leilões em
            mais de 12 estados brasileiros.
          </p>
          <p>
            A <strong>WebZI</strong>, lança sua ferramenta de venda online
            própria, atendendo as necessidades do público leiloeiro, oferecendo
            o que há de mais moderno em recursos tecnológicos de segurança
            avançados e serviços financeiros para que possa utilizar sua conta
            nos principais bancos do Brasil.
          </p>
          <p>
            A Plataforma da
            <a href="https://www.ebonline.com.br">EBLONLINE.COM.BR</a> foi
            desenvolvida para hastas judiciais, administrativos do setor
            publico, como Detrans, Prefeituras, Órgãos de Justiça para veículos,
            imóveis, embarcações, equipamentos eletrônicos e etc...
          </p>
          <p>
            Para facilitar a sua experiência com o leilão, oferecemos serviços
            de: documentação e financiamento para veículos e imóveis,
            despachante, vistoria especial, remoção e guarda de veículos, além
            de um especializado atendimento ao cliente.
          </p>
          <p>
            O Produto
            <strong>EBL, Empresa Brasileira de Leilões</strong> juntamente a sua
            plataforma de leilões
            <a href="https://www.ebonline.com.br">www.eblonline.com.br</a> são
            marcas em processo de registro da empresa:
          </p>
          <p><strong>WebZI Pesquisa & Inovação tecnológica Ltda.</strong></p>
          <p>
            <a href="mailto:contato@eblonline.com.br"
              >contato@eblonline.com.br</a
            >
          </p>
          <p>Cnpj: 37.552.169/0001-64</p>
          <p>AV Adalgisa Colombo Miss Brasil, 00135 LOJ 2067</p>
          <p>Jacarepaguá - Rio de Janeiro</p>
          <p>CEP 22775-026 - RJ</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}
</style>